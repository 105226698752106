




























import { Component, Vue } from 'vue-property-decorator';
import SingleCollaborationItem from '@/components/collaboration/SingleCollaborationItem.vue';

@Component({
  components: { SingleCollaborationItem },
})
export default class Auditions extends Vue {}
